import React from 'react';

const FooterArticle = () => {
	return (
		<div className="post-related">
			<h3>Related Articles</h3>
			<br className="clear" />
			<div className="one-third ">
				<div
					id="post-3025"
					className="post-3025 post type-post status-publish format-standard has-post-thumbnail hentry category-direction category-entrepreneur tag-direction tag-vision-goal-setting"
				>
					<div className="post-wrapper grid-layout has-featured-img">
						<a
							className="post-related-link"
							href="https://themes.themegoods.com/avante/blog/the-biggest-mistake-you-can-make-when-setting-new-goals/"
						/>
						<img
							src="https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/business-people-working-in-office-UG93BVM-610x610.jpg"
							className="post-related-featured-img"
							alt=""
						/>
						<div className="post-header-wrapper">
							<div className="post-detail single-post">
								<span className="post-info-cat">
									<a href="https://themes.themegoods.com/avante/blog/category/direction/">
										Direction
									</a>
									&nbsp;·&nbsp;{' '}
									<a href="https://themes.themegoods.com/avante/blog/category/entrepreneur/">
										Entrepreneur
									</a>
								</span>
							</div>
							<div className="post-header grid related">
								<h6>
									<a
										href="https://themes.themegoods.com/avante/blog/the-biggest-mistake-you-can-make-when-setting-new-goals/"
										title="The Biggest Mistake You Can Make When Setting New Goals"
									>
										The Biggest Mistake You Can Make When Setting New Goals
									</a>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="one-third ">
				<div
					id="post-190"
					className="post-190 post type-post status-publish format-standard has-post-thumbnail hentry category-career category-family category-productivity tag-direction tag-vision-goal-setting"
				>
					<div className="post-wrapper grid-layout has-featured-img">
						<a
							className="post-related-link"
							href="https://themes.themegoods.com/avante/blog/why-diversification-of-marketing-strategies-is-vital-in-2019-2/"
						/>
						<img
							src="https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-610x610.jpg"
							className="post-related-featured-img"
							alt=""
						/>
						<div className="post-header-wrapper">
							<div className="post-detail single-post">
								<span className="post-info-cat">
									<a href="https://themes.themegoods.com/avante/blog/category/career/">Career</a>
									&nbsp;·&nbsp;{' '}
									<a href="https://themes.themegoods.com/avante/blog/category/family/">Family</a>
									&nbsp;·&nbsp;{' '}
									<a href="https://themes.themegoods.com/avante/blog/category/productivity/">
										Productivity
									</a>
								</span>
							</div>
							<div className="post-header grid related">
								<h6>
									<a
										href="https://themes.themegoods.com/avante/blog/why-diversification-of-marketing-strategies-is-vital-in-2019-2/"
										title="Why Diversification of Marketing Strategies is Vital in 2019"
									>
										Why Diversification of Marketing Strategies is Vital in 2019
									</a>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="one-third last">
				<div
					id="post-3023"
					className="post-3023 post type-post status-publish format-video has-post-thumbnail hentry category-career category-entrepreneur tag-direction tag-vision-goal-setting post_format-post-format-video"
				>
					<div className="post-wrapper grid-layout has-featured-img">
						<a
							className="post-related-link"
							href="https://themes.themegoods.com/avante/blog/looking-to-bridge-the-gap-between-marketing-and-technology/"
						/>
						<img
							src="https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/group-of-people-business-meeting-concept-PTALZ6T-610x610.jpg"
							className="post-related-featured-img"
							alt=""
						/>
						<div className="post-header-wrapper">
							<div className="post-detail single-post">
								<span className="post-info-cat">
									<a href="https://themes.themegoods.com/avante/blog/category/career/">Career</a>
									&nbsp;·&nbsp;{' '}
									<a href="https://themes.themegoods.com/avante/blog/category/entrepreneur/">
										Entrepreneur
									</a>
								</span>
							</div>
							<div className="post-header grid related">
								<h6>
									<a
										href="https://themes.themegoods.com/avante/blog/looking-to-bridge-the-gap-between-marketing-and-technology/"
										title="Bridge the Gap Between Marketing and Technology?"
									>
										Bridge the Gap Between Marketing and Technology?
									</a>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterArticle;
