import React from 'react';
import './cssberhasil';
import LoadingLogo from '../../upload/loading_logo.png';
import Header from './HeaderSub';
import Navbar from './NavbarSub';
import Footer from './Footer';
import { Link } from 'gatsby';
import Banner from '../content/404/Banner';
import Helmet from 'react-helmet';
import JSberhasil from './jsberhasil';
import Mobile from '../content/Mobile';
import HeaderNews from '../content/News/News3/HeaderNews';
import NewsContent from '../content/News/News3/NewsContent';
import ContentPhoto from '../content/News/News3/ContentPhoto';
import NewsContent2 from '../content/News/News3/NewsContent2';
import NewsContent3 from '../content/News/News3/NewsContent3';
import FooterArticle from '../content/News/News3/FooterArticle';

const LayoutNews1 = () => {
	return (
		// <>
		<React.Fragment>
			<Helmet
				bodyAttributes={{
					// 'home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074'
					class:
						'post-template-default single single-post postid-203 single-format-video theme-avante woocommerce-js lightbox-black leftalign footer-reveal tg-single-course-1 elementor-default elementor-kit-7260 elementor-page elementor-page-203 loaded has-smoove'
				}}
			/>

			{/*
			<div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
			</div>
			*/}

			<div id="perspective">
				<Mobile />
				<div id="wrapper" className="hasbg transparent" style={{ paddingTop: '0px' }}>
					<div id="elementor-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							className="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Header />
									<Navbar />
								</div>
							</div>
						</div>
					</div>
					<div id="elementor-sticky-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							className="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Navbar />
								</div>
							</div>
						</div>
					</div>
					<HeaderNews />
					<div id="page-content-wrapper" className="blog-wrapper hasbg">
						<div className="inner">
							<div className="inner-wrapper">
								<div className="sidebar-content fullwidth blog_f">
									<div
										id="post-203"
										className="post-203 post type-post status-publish format-video has-post-thumbnail hentry category-career category-family category-life tag-direction tag-vision-goal-setting post_format-post-format-video"
									>
										<div className="post-wrapper">
											<div
												data-elementor-type="wp-post"
												data-elementor-id={203}
												className="elementor elementor-203"
												data-elementor-settings="[]"
											>
												<div className="elementor-inner">
													<div className="elementor-section-wrap">
														<NewsContent />
														<ContentPhoto />
														<NewsContent2 />
														<NewsContent3 />
														<FooterArticle />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<br className="clear" />
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<JSberhasil />
			{/* </> */}
		</React.Fragment>
	);
};

export default LayoutNews1;
